/* eslint-disable */
/* @ts-nocheck */

import React, { useState, useEffect, useMemo } from 'react';
import Page from '../../components/Page';
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Modal,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatCurrency } from 'src/utils/formatCurrency';
import axiosMB from 'src/utils/axios/interceptors';
import { bookingDetailsStatusColors, bookingDetailsStatusLabel } from 'src/utils/statuses/bookings';
import { AssignAsset } from './AssignAsset';
import { AssignEmployee } from './AssignEmployee';
import { Icon } from '@iconify/react';
import { partnerLedgerLabels } from 'src/utils/statuses/partners';
import moment from 'moment';

const PartnerBookingDetails = () => {
    const { pathname } = useLocation();
    const partnerId = pathname.split("/")[2];
    const [loading, setLoading] = useState(true);
    const [bookingDetails, setBookingDetails] = useState([]);
    const [bookings, setBookings] = useState({
        ongoing: [],
        upcoming: [],
        completed: [],
        done: [],
    });
    const [value, setValue] = useState(0);
    const [sortBy, setSortBy] = useState("startDate");
    const [sortDirection, setSortDirection] = useState("asc");

    const handleChange = (event, newValue) => {
        switch (newValue) {
            case 2:
                setSortBy("endDate")
                setSortDirection("desc")
                break;
            default:
                setSortBy("startDate")
                setSortDirection("asc")
                break;
        }
        setValue(newValue);
    };

    const sortDirectionFunction = (a, b) => {
        if (sortDirection == "asc") {
            return new Date(a[sortBy]).setHours(0, 0, 0, 0) - new Date(b[sortBy]).setHours(0, 0, 0, 0);
        }
        return new Date(b[sortBy]).setHours(0, 0, 0, 0) - new Date(a[sortBy]).setHours(0, 0, 0, 0);
    }

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${API_URL}/partner/bookings?partnerId=${partnerId}`);
                const data = await response.json();
                setBookingDetails(data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch bookingDetails:', error);
            }
        };
        fetchBookings();
    }, []);

    const pendingFilter = (booking) => {
        if (moment(booking.endDate).isBefore(moment().startOf('day')) &&
            !(partnerLedgerLabels[booking.status] == "Cancelled" || partnerLedgerLabels[booking.status] == "Settlement Done") &&
            booking.status !== "partner-settlement-pending" &&
            booking.status !== "partner-settlement-acknowledgement-pending"
        ) {
            return booking
        }
    }

    const settlementDoneFilter = (booking) => {
        if (partnerLedgerLabels[booking.status] === "Cancelled" || partnerLedgerLabels[booking.status] === "Settlement Done") {
            return booking
        }
    }
    useMemo(() => {
        const upcomingBookings = bookingDetails.filter(booking => moment(booking.startDate).isAfter(moment().startOf('day')))
            .sort(sortDirectionFunction)
            .map((booking) => <PartnerBookingCard
                key={booking.id}
                booking={booking}
                partnerConformationPending={booking.status === 'confirmation-pending'}
                partnerActionPending={booking.status === 'confirmed-and-partner-action-pending'}
            />)

        const ongoingBookings = bookingDetails.filter(booking => {
            return moment(booking.startDate).startOf('day').isSameOrBefore(moment().startOf('day')) && moment(booking.endDate).startOf('day').isSameOrAfter(moment().startOf('day'))
        })
            .sort(sortDirectionFunction)
            .map((booking) => <PartnerBookingCard key={booking.id}
                booking={booking}
                partnerConformationPending={booking.status === 'confirmation-pending'}
                partnerActionPending={booking.status === 'confirmed-and-partner-action-pending'}
            />)

        const completedBookings = bookingDetails.filter(pendingFilter)
            .sort(sortDirectionFunction)
            .map((booking) => <PartnerBookingCard key={booking.id}
                booking={booking}
                partnerConformationPending={booking.status === 'confirmation-pending'}
                partnerActionPending={booking.status === 'confirmed-and-partner-action-pending'}
            />)
        const doneBookings = bookingDetails.filter(settlementDoneFilter)
            .sort(sortDirectionFunction).map((booking) => <PartnerBookingCard key={booking.id}
                booking={booking}
                partnerConformationPending={booking.status === 'confirmation-pending'}
                partnerActionPending={booking.status === 'confirmed-and-partner-action-pending'}
            />)

        setBookings({
            ongoing: ongoingBookings.length ? ongoingBookings : null,
            upcoming: upcomingBookings.length ? upcomingBookings : null,
            completed: completedBookings.length ? completedBookings : null,
            done: doneBookings.length ? doneBookings : null,
        })
    }, [bookingDetails, value]);



    return (
        <Page>
            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Bookings
                </Typography>
                {!loading ? (<><Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                        variant="scrollable"    // Enables horizontal scrolling
                        scrollButtons="auto"    // Adds scroll buttons when necessary
                        allowScrollButtonsMobile  // Makes sure buttons appear on mobile
                    >
                        <Tab label="Ongoing" {...a11yProps(0)} />
                        <Tab label="Upcoming" {...a11yProps(1)} />
                        <Tab label="Completed" {...a11yProps(2)} />
                        <Tab label="Done" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                    <CustomTabPanel value={value} index={0}>
                        {bookings.ongoing || <div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>No Ongoing Bookings</div>}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        {bookings.upcoming || <div className='grid grid-cols-1 gap-4  my-5 font-bold text-xl'>No Upcoming Bookings</div>}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        {bookings.completed || <div className='grid grid-cols-1 gap-4  my-5 font-bold text-xl'>No Completed Bookings</div>}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        {bookings.done || <div className='grid grid-cols-1 gap-4  my-5 font-bold text-xl'>No Done Bookings</div>}
                    </CustomTabPanel>
                </>) :
                    (<div className='grid grid-cols-1 gap-4 my-5 font-bold text-xl'>Loading...</div>)
                }
            </Container>
        </Page>
    );
};

const phoneNumberMask = ({ startDate, endDate, phoneNumber }) => {
    if (phoneNumber) {
        const twoDaysFromToday = moment().add(2, 'days').startOf('day');
        if (endDate) {
            if (moment(startDate).isSameOrAfter(twoDaysFromToday) || moment().isSameOrAfter(moment(endDate).startOf('day'))) {
                return phoneNumber.split('').map((char, index) => {
                    if (index > 2 && index < phoneNumber.length - 4) {
                        return '*';
                    }
                    return char;
                }).join('');
            }
        } else if (moment(startDate).isSameOrAfter(twoDaysFromToday)) {
            return phoneNumber.split('').map((char, index) => {
                if (index > 2 && index < phoneNumber.length - 4) {
                    return '*';
                }
                return char;
            }).join('');
        }
        return phoneNumber;
    }
    return phoneNumber;
}

const PartnerBookingCard = ({
    booking,
    partnerActionPending,
    partnerConformationPending
}) => {
    const {
        id,
        startDate,
        endDate,
        dateLabel,
        status,
        guestName,
        guestPhone,
        amountPartnerSettlementActual,
        amountPartnerToBeCollected,
        amountPartnerSettlementLeft,
        bookingsSummary,
        activityType,
        assignedAssets
    } = booking;
    const [open, setOpen] = useState(false);
    const [assignOpen, setAssignOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [bookingAction, setBookingAction] = useState(null);
    const [assignAssetOpen, setAssignAssetOpen] = useState(false);
    const handleClickOpen = () => { setOpen(true) };
    const handleConfirmOpen = () => { setConfirmOpen(true) };
    const handleConfirmClose = () => { setConfirmOpen(false) };
    const handleBookingConfirmation = async (action) => {
        try {
            const response = await axiosMB.patch(
                `${API_URL}/admin/mb/bookings/${id}`,
                {
                    updateFields: {
                        status: action === 'cancel' ? 'cancelled-refund-pending' : 'confirmed',
                    }
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                location.reload();
            } else {
                console.error('Failed to submit booking:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting booking:', error);
        }
    }

    return (
        <div style={{ boxShadow: `-12px 0 0px ${bookingDetailsStatusColors[status]}` }}
            className='grid grid-cols-1 sm:grid-cols-5 gap-2 bg-white p-4 rounded-lg shadow-md'>
            <div className='flex sm:flex-col'>
                <div>
                    <p className='font-bold'>{guestName}</p>
                    <p>{phoneNumberMask({ startDate, endDate, phoneNumber: guestPhone })}</p>
                </div>
                <Button variant='outlined' className="max-sm:ml-auto sm:mt-auto" onClick={() => console.log()} >{bookingDetailsStatusLabel[status]}</Button>
            </div>
            <Divider className='col-span-full sm:hidden' orientation="horizontal" />
            <div className='grid grid-cols-1 sm:grid-cols-2 place-items-start sm:col-span-2'>
                <p className='whitespace-nowrap'>{dateLabel}</p>
                <p>{bookingsSummary}</p>
            </div>
            <Divider className='col-span-full sm:hidden' orientation="horizontal" />
            <div className='flex flex-col sm:place-self-center w-full'>
                <div className='flex items-center justify-between font-bold gap-2 whitespace-nowrap'><p className='' >Your Price: </p>{formatCurrency(amountPartnerSettlementActual)}</div>
                <div className='flex items-center justify-between font-bold gap-2'><p className='' >Collect from Customer:</p> {formatCurrency(amountPartnerToBeCollected)}</div>
                <div className='flex items-center justify-between font-bold gap-2 whitespace-nowrap'><p className='' >We will pay:</p> {formatCurrency(amountPartnerSettlementLeft)}</div>
            </div>
            <Divider className='col-span-full sm:hidden' orientation="horizontal" />
            {/* <div className='flex flex-col h-full items-center justify-center'>
                <button
                    className='outline-1 outline outline-[#da4848] hover:bg-[#da4848]/5  text-[#da4848] w-full max-w-[150px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                    onClick={handleViewOpen}
                >View</button>
            </div> */}
            {partnerConformationPending && (
                <div className='grid grid-cols-2 sm:grid-cols-1 gap-4'>
                    <button
                        onClick={() => {
                            handleConfirmOpen()
                            setBookingAction('cancel')
                        }}
                        className='border-2 border-[#da4848] text-[#da4848] w-full max-w-[150px] max-h-36 font-extralight p-1.5 rounded-lg whitespace-nowrap place-self-center scale-95'>
                        Cancel Booking
                    </button>
                    <Button
                        onClick={() => {
                            handleConfirmOpen()
                            setBookingAction('confirm')
                        }}
                        className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] max-h-36 font-extralight p-1.5 rounded-lg whitespace-nowrap place-self-center'>
                        Confirm Booking
                    </Button>
                </div>)}

            {(!status.startsWith('completed') && !status.startsWith('cancelled') && status !== 'partner-settlement-pending') && <Button
                onClick={() => { setAssignOpen(true) }}
                className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] max-h-36 font-extralight p-1.5 rounded-lg whitespace-nowrap place-self-center'>
                Assign
            </Button>}
            {assignOpen && <Modal
                open={assignOpen} onClose={() => { setAssignOpen(false) }}
            >
                <Box
                    className='absolute flex flex-col p-6 px-8 bg-white rounded-lg sm:top-1/2 left-1/2 bottom-0 -translate-x-1/2 sm:-translate-y-1/2 w-full max-w-[800px] h-full max-h-screen sm:max-h-[90vh]'
                >
                    <button onClick={() => { setAssignOpen(false) }} className='border rounded-xl p-3 px-4 bg-slate-100/40 mb-2 max-w-min'>
                        <Icon icon="material-symbols-light:close" width="1em" height="1em" />
                    </button>
                    <h1 className='my-2 text-lg font-medium'>Complete actions for booking</h1>
                    <div className='flex flex-col items-center justify-center gap-6 mt-6'>
                        <div className='flex items-center justify-between w-full'>
                            <p className='font-semibold'>Employee</p>
                            <Button
                                onClick={handleClickOpen}
                                className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] max-h-36 font-extralight p-1.5 rounded-lg whitespace-nowrap place-self-center'>
                                {partnerActionPending ? 'Assign Employee' : 'Change Employee'}
                            </Button>
                        </div>
                        {activityType === 'taxi-tour' && !status.startsWith('completed') && !status.startsWith('cancelled') &&
                            <div className='flex items-center justify-between w-full'>
                                <p className='font-semibold'>Asset</p>
                                <Button
                                    onClick={() => { setAssignAssetOpen(true) }}
                                    className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[150px] max-h-36 font-extralight p-1.5 rounded-lg whitespace-nowrap place-self-center'>
                                    {assignedAssets && assignedAssets?.length > 0 ? 'Change Asset' : 'Assign Asset'}
                                </Button>
                            </div>}
                    </div>

                </Box>
            </Modal>}

            {
                open && (
                    <AssignEmployee
                        booking={booking}
                        isOpen={open}
                        onClose={() => {
                            setOpen(false);
                        }}
                    />
                )
            }
            {
                assignAssetOpen && (
                    <AssignAsset
                        booking={booking}
                        isOpen={assignAssetOpen}
                        onClose={() => {
                            setAssignAssetOpen(false)
                        }}
                    />
                )
            }
            {
                confirmOpen && (
                    <Dialog open={confirmOpen} onClose={handleConfirmClose}>
                        <DialogTitle>Are you sure?</DialogTitle>
                        <DialogContent className="min-w-full overflow-visible ">
                            <div className='grid grid-cols-1 gap-2'>
                                {bookingAction === 'cancel' ? (
                                    <div className='grid grid-cols-1 gap-8'>
                                        <h1>You cannot undo this action.</h1>
                                        <h1 className='font-bold'>  Booking will be cancelled.</h1>
                                        <div className='grid grid-cols-1 gap-4'>
                                            <button className='text-xs' onClick={() => handleBookingConfirmation(bookingAction)}>Cancel</button>

                                            <Button
                                                onClick={() => {
                                                    handleConfirmClose()
                                                    setBookingAction(null)
                                                }}
                                                className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[250px] h-16 font-extralight p-2 rounded-lg whitespace-nowrap'>
                                                Go Back
                                            </Button>

                                        </div>
                                    </div>
                                ) : bookingAction === 'confirm' ? (
                                    <div className='grid grid-cols-1 gap-8'>
                                        <h1>Booking will be confirmed.</h1>
                                        <div className='grid grid-cols-1 gap-4'>
                                            <Button
                                                onClick={() => {
                                                    handleBookingConfirmation(bookingAction);
                                                }}
                                                className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[250px] h-16 font-extralight p-2 rounded-lg whitespace-nowrap'>
                                                Confirm
                                            </Button>
                                            <button className='text-xs' onClick={() => {
                                                handleConfirmClose()
                                                setBookingAction(null)
                                            }}>Go Back</button>
                                        </div>
                                    </div>
                                ) : (<div></div>)}
                            </div>
                        </DialogContent>
                    </Dialog>
                )
            }
        </div >
    );
}





const PartnerBookingFullDetailsCard = ({ booking }) => {
    const { id,
        orderId,
        activityTitle,
        date,
        guestName,
        guestPhone,
        startDate,
        endDate,
        startDateLabel,
        bookingsSummary,
        amountPartnerSettlementActual,
        amountPartnerAdvancePaid,
        amountPartnerToBeCollected, } = booking;

    return (
        <div className='grid grid-cols-2 sm:grid-cols-3 gap-2'>
            <div><p className='font-bold'>Order Id:</p> {orderId}</div>
            <div><p className='font-bold'>Booking Id:</p> {id}</div>
            <div><p className='font-bold'>Activity Title:</p> {activityTitle}</div>
            <Divider className='col-span-full' orientation="horizontal" flexItem />
            <h1 className='font-bold text-xl col-span-full'>Schedule Details</h1>
            <div><p className='font-bold'>Date:</p> {date}</div>
            <div><p className='font-bold'>Start Date:</p> {startDateLabel}</div>
            <Divider className='col-span-full' orientation="horizontal" flexItem />
            <h1 className='font-bold text-xl col-span-full'>Customer Details</h1>
            <div><p className='font-bold'>Name:</p> {guestName}</div>
            <div><p className='font-bold'>Phone:</p> {phoneNumberMask({ startDate, endDate, phoneNumber: guestPhone })}</div>
            <div><p className='font-bold'>Summary:</p> {bookingsSummary}</div>
            <Divider className='col-span-full' orientation="horizontal" flexItem />
            <h1 className='font-bold text-xl col-span-full'>Price Details</h1>
            <div><p className='font-bold'>Your Price:</p> {formatCurrency(amountPartnerSettlementActual)}</div>
            <div><p className='font-bold'>Advance Paid:</p> {formatCurrency(amountPartnerAdvancePaid)}</div>
            <div><p className='font-bold'>To Be Collected:</p> {formatCurrency(amountPartnerToBeCollected)}</div>
        </div>)
}

PartnerBookingFullDetailsCard.propTypes = {
    booking: PropTypes.object.isRequired
};
PartnerBookingCard.propTypes = {
    booking: PropTypes.object.isRequired,
    partnerActionPending: PropTypes.bool,
    partnerConformationPending: PropTypes.bool,

};
export default PartnerBookingDetails;



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box className='grid grid-cols-1 gap-4 p-2' sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
