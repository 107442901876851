/* eslint-disable */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    Stack,
    CircularProgress,
    IconButton,
    Autocomplete,
    Switch
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import Page from '../components/Page';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { UploadButton } from 'src/components/buttons/upload';

export default function AddActivityVariants() {
    const [activity, setActivity] = useState({});
    const [variants, setVariants] = useState([]);
    const [newVariants, setNewVariants] = useState([]);
    const [isLoading, setLoader] = useState(false);
    const { slug } = useParams();
    const [amenities, setAmenities] = useState([]);

    const getStayAmenities = async () => {
        try {
            const res = await axios.get(`${API_URL}/admin/mb/stayAmenities`);
            setAmenities(res.data.map((amenity) => ({ label: amenity.name, value: amenity.id })));
        } catch (error) {
            console.log(error);
        }
    };

    const getActivityVariants = async () => {
        try {
            const activityId = slug.split("-")[0];
            const res = await axios.get(`${API_URL}/admin/mb/activty/variants?activityId=${activityId}`);
            console.log(res.data);
            setActivity(res.data);
            setVariants(res.data.variants);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getActivityVariants();
        getStayAmenities();
    }, []);

    const addNewVariant = useCallback((type) => {
        console.log(type);
        setNewVariants(prev => [...prev, type === "stay" ? {
            title: "",
            desc: "",
            minPerson: 1,
            maxPerson: 1,
            stayRoomAmenities: [],
            banners: [],
            pricing: [
                { title: "EP", type: "stay-meal-ep", extraPrices: [{ id: "stay-extra-adult", title: "Extra Adult", price: 0 }, { id: "stay-extra-kid", title: "Extra Kid", price: 0 }], maxQuantity: 3, minQuantity: 2, isDefault: true, price: 0 },
                { title: "CP", type: "stay-meal-cp", extraPrices: [{ id: "stay-extra-adult", title: "Extra Adult", price: 0 }, { id: "stay-extra-kid", title: "Extra Kid", price: 0 }], maxQuantity: 3, minQuantity: 2, isDefault: true, price: 0 },
                { title: "MPAI", type: "stay-meal-mapi", extraPrices: [{ id: "stay-extra-adult", title: "Extra Adult", price: 0 }, { id: "stay-extra-kid", title: "Extra Kid", price: 0 }], maxQuantity: 3, minQuantity: 2, isDefault: true, price: 0 },
                { title: "APAI", type: "stay-meal-apai", extraPrices: [{ id: "stay-extra-adult", title: "Extra Adult", price: 0 }, { id: "stay-extra-kid", title: "Extra Kid", price: 0 }], maxQuantity: 3, minQuantity: 2, isDefault: true, price: 0 },
            ],
        } : {
            title: "",
            desc: "",
            minPerson: 1,
            maxPerson: 1,
            stayRoomAmenities: [],
            banners: [],
            pricing: [
                {
                    title: 'Person',
                    maxQuantity: 20,
                    minQuantity: 1,
                    price: 0,
                    isDefault: true,
                },
            ],
        }]);
    }, []);
    console.log(newVariants);

    const addRoomAmenity = useCallback((index) => {
        setNewVariants(prev => prev.map((variant, idx) => idx === index ? {
            ...variant,
            stayRoomAmenities: [...variant.stayRoomAmenities, { name: "", icon: "" }]
        } : variant));
    }, []);

    const removeRoomAmenity = useCallback((variantIndex, amenityIndex) => {
        setNewVariants(prev => prev.map((variant, idx) => idx === variantIndex ? {
            ...variant,
            stayRoomAmenities: variant.stayRoomAmenities.filter((_, i) => i !== amenityIndex)
        } : variant));
    }, []);

    const handleInputChange = useCallback((e, index, field, isExisting = false) => {
        const { value } = e.target;
        const setVariantsFunction = isExisting ? setVariants : setNewVariants;
        setVariantsFunction(prev => prev.map((variant, idx) => idx === index ? { ...variant, [field]: value } : variant));
    }, []);

    const handleAmenityChange = useCallback((e, variantIndex, amenityIndex, field, isExisting = false) => {
        const setVariantsFunction = isExisting ? setVariants : setNewVariants;
        setVariantsFunction(prev => {
            const updatedVariants = [...prev];
            updatedVariants[variantIndex].stayRoomAmenities[amenityIndex][field] = field === 'available'
                ? e.target.checked
                : e.target.value;
            return updatedVariants;
        });
    }, []);

    const optimizedVariants = useMemo(() => newVariants, [newVariants]);

    const saveFinalData = async () => {
        setLoader(true);
        const activityId = slug.split("-")[0];
        const body = {
            activityType: activity.type,
            activityId,
            variants: newVariants.filter(variant => variant.title.trim() !== "")
        }
        const res = await axios.post(
            `${API_URL}/admin/mb/activty/variants`,
            body
        );
        if (res.status === 200) {
            location.reload()
        }

        // Implement save function
        setLoader(false);
    };

    const returnOnlyNumberFromEvent = (e) => ({
        ...e,
        target: {
            ...e.target,
            value: Number(e.target.value.replace(/[^0-9]/g, ''))
        }
    });
    const removeNewVariant = (index) => {
        setNewVariants(prev => prev.filter((_, i) => i !== index));
    }
    console.log(activity);
    return (
        <Page title="Edit Activity Variants">
            <Container>
                <Typography variant="h4" sx={{ mb: 2 }}>{activity.title}</Typography>
                <Button variant="outlined" onClick={() => addNewVariant(activity.type)} className='my-4'>Add new variant</Button>
                <Grid container spacing={3}>
                    {optimizedVariants.map((variant, index) => (
                        <Grid item xs={12} md={12} lg={12} key={index}>
                            <Card>
                                <CardHeader title={`New Variant ${index + 1}`}
                                    action={
                                        <Button variant="outlined" onClick={() => removeNewVariant(index)}>
                                            Remove
                                        </Button>
                                    }
                                />
                                <CardContent>
                                    <Stack spacing={2}>
                                        <TextField
                                            label="Title"
                                            value={variant.title}
                                            onChange={(e) => handleInputChange(e, index, 'title')}
                                            fullWidth
                                        />
                                        <TextField
                                            label="Description"
                                            value={variant.desc}
                                            onChange={(e) => handleInputChange(e, index, 'desc')}
                                            fullWidth
                                        />
                                        <TextField
                                            label="Min Person"
                                            value={variant.minPerson}
                                            onChange={(e) => handleInputChange(returnOnlyNumberFromEvent(e), index, 'minPerson')}
                                            fullWidth
                                        />
                                        <TextField
                                            label="Max Person"
                                            value={variant.maxPerson}
                                            onChange={(e) => handleInputChange(returnOnlyNumberFromEvent(e), index, 'maxPerson')}
                                            fullWidth
                                        />
                                        <Typography variant="subtitle2" sx={{ mt: 2 }}>Photos:</Typography>
                                        <div className='grid grid-cols-5 gap-4 my-3'>
                                            {variant.banners.map(banner => (
                                                <img src={banner} key={banner} alt={banner} />
                                            ))}
                                        </div>
                                        <UploadButton
                                            title={'Upload Images'}
                                            uploadUrls={[]}
                                            onUploadUrlChange={(data) => {
                                                setNewVariants(prev => {
                                                    const newVariants = [...prev];
                                                    newVariants[index].banners.push(data[0]);
                                                    return newVariants;
                                                });
                                            }}
                                        />
                                        {activity.type === "stay" && < Typography variant="subtitle2" sx={{ mt: 2 }}>Room Amenities:</Typography>}
                                        {activity.type === "stay" && variant.stayRoomAmenities.map((amenity, amenityIndex) => (
                                            <Stack key={amenityIndex} direction="row" spacing={2} alignItems="center">
                                                <Autocomplete
                                                    options={amenities}
                                                    getOptionLabel={(option) => option.label}
                                                    value={amenity.amenityId}
                                                    onChange={(e, newValue) =>
                                                        handleAmenityChange({ target: { value: newValue?.value || '' } }, index, amenityIndex, 'amenityId')
                                                    }
                                                    renderInput={(params) => <TextField {...params} label="Amenity ID" />}
                                                    fullWidth
                                                />
                                                <Switch
                                                    checked={amenity.available}
                                                    onChange={(e) => handleAmenityChange(e, index, amenityIndex, 'available')}
                                                    color="primary"
                                                />
                                                <IconButton onClick={() => removeRoomAmenity(index, amenityIndex)} color="error">
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Stack>
                                        ))}
                                        {activity.type === "stay" && <Button
                                            variant="outlined"
                                            onClick={() => addRoomAmenity(index)}
                                            startIcon={<AddIcon />}
                                            sx={{ mt: 2 }}
                                        >
                                            Add Amenity
                                        </Button>}
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                    {variants.map((variant, index) => (
                        <Grid item xs={12} md={12} lg={12} key={variant._id}>
                            <Card>
                                <CardHeader title={variant.title || `Variant ${index + 1}`}
                                    action={
                                        <Link target='_blank' to={`/dashboard/edit-activity-variant/${variant._id}`}>
                                            <Button variant="outlined">
                                                Edit
                                            </Button>
                                        </Link>
                                    }
                                />
                                <CardContent>
                                    <Stack spacing={2}>
                                        <TextField
                                            label="Title"
                                            value={variant.title}
                                            fullWidth
                                            disabled={true}
                                        />
                                        <TextField
                                            label="Description"
                                            value={variant.desc}
                                            fullWidth
                                            disabled={true}
                                        />
                                        <div className='grid grid-cols-5 gap-2'>
                                            {variant.banners?.items?.map(banner => {
                                                return <img src={banner.url} key={banner.url} alt="banner.src" />
                                            })}
                                        </div>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}

                </Grid>
                <Button
                    variant="contained"
                    onClick={saveFinalData}
                    sx={{ mt: 3 }}
                    startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
                >
                    Save All
                </Button>
            </Container>
        </Page >
    );
}
